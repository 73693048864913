<template>
  <div class="invalid-div">
    <div class="img-dev">
      <img class="img1" :src="warningImg">
      <p class="verify-t">{{ $t('refinancing.invalid') }}</p>
      <p class="verify-s">{{ $t('refinancing.invalidDesc') }}</p>
    </div>

    <div class="btn-group">
      <Button
        class="fu-v2-btn block"
        round
        block
        type="danger"
        @click="close"
      >{{ $t('refinancing.close') }}</Button>
    </div>
  </div>
</template>

<script>
import { Button } from 'vant'
import warningImg from '@/assets/imgs/warning.png'
// import('@/views/claim/eb/css/reset.css')
export default {
  components: { Button },
  props: {},
  data() {
    return {
      warningImg
    }
  },
  methods: {
    close() {
      window.close()
    }
  }
}
</script>
<style lang="scss" scoped>
.invalid-div {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 91;
  background-color: #fff;
}
.img-dev {
  margin-top: 111px;
  text-align: center;
}
.img1 {
  width: 270px;
}
.verify-t {
  font-size: 16px;
  font-family: 'Montserrat-Bold';
  margin: 15px 0;
}
.verify-s {
  font-size: 12px;
  margin: 0 45px;
  text-align: center;
}
.btn-group {
  margin: 20px 20px;
}
</style>
